.card {
  margin: 10px;
  background-color: var(--card-background);
  border: 0;
  box-shadow: 0 1px 20px 0 rgb(0 0 0 / 10%);
  border-radius: 0.25rem;
  transition: all var(--default-transition);
  overflow: hidden;

  // Card Header
  .header {
    padding: 0 10px;
    background-color: var(--sub-card-background);
    background-image: linear-gradient(
      90deg,
      var(--card-header-highlight) 0,
      rgba(201, 189, 174, 0) 25%
    );
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    min-height: 50px;

    display: flex;
    align-items: center;
    overflow: hidden;

    // Left align header
    h2 {
      margin-left: 0;
    }
  }

  // Card Body
  .content {
    padding: 10px;

    // List item styling
    section {
      // All direct children need a max-width of 100%;
      * {
        max-width: 100%;
      }
      ul {
        list-style: disc;
        margin-left: 10px;
        li {
          margin-left: 20px;
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }

    h2 {
      margin-left: 0;
      margin-right: 0;
      font-size: 1.8rem;
      font-weight: 500;
    }

    // Checkboxes
    .task-list-item {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-left: 0;
    }
  }

  //=======================================================
  // Special Selectors
  //=======================================================

  // Removes content padding (for displaying things such as s list view)
  &.no-content-pad {
    .content {
      padding: 0;
    }
  }

  // Removes the color from the header
  &.no-header-bg {
    .header {
      // This color should be the 'selected' color of our list items
      background-image: none;
    }
  }
}
