.sidebar {
  max-width: 230px;
  width: 100%;
  background-color: var(--card-background);
  box-shadow: 0 0 0.5rem 0 rgb(0 0 0 / 60%);
  transform-origin: 0 50%;
  transition: width var(--default-transition);
  overflow: hidden;
  text-transform: uppercase;

  // Contains workspace + project dropdown
  .header {
    padding: 16px;
    padding-top: 20px;
    box-shadow: 0 0 0.5rem 0 rgb(0 0 0 / 60%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // Workspace Name
    .title {
      text-transform: uppercase;
      color: var(--text-brighter);
      font-weight: 500;
      font-size: 1rem;
      text-align: center;
    }

    // Button to open/collapse projects
    .dropdown-button {
      margin: 5px 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    // Contains projects + header below Project Selection Dropdown button
    .projects-container {
      height: 0;
      overflow: hidden;
      transition: height 0.5s ease;

      &.open {
        max-height: 350px;
        height: 20px;
      }

      .projects-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid var(--text-brighter);

        font-size: 0.625rem;
        padding: 5px 0;

        button {
          font-size: inherit;
          text-transform: inherit;
        }

        p {
          margin: 0;
        }
      }

      // List 5 most recent projects
      .projects-list {
        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          display: flex;
          flex-direction: column;
          justify-content: center;

          li {
            text-align: center;
            padding: 5px 0;

            button {
              text-transform: uppercase;
              font-size: 0.625rem;
            }
          }
        }
      }
    }
  }

  // Contains links
  .navigation {
    padding: 16px;
    padding-top: 8px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      display: flex;
      flex-direction: column;
      justify-content: center;

      li {
        padding: 14px 0;

        // Navbar Links
        a {
          font-size: 0.65rem;
          display: flex;
          align-items: center;

          // primary color
          &:hover,
          &:focus {
            color: var(--text-primary);
          }

          // Styling for current route
          &.active {
            color: white;
            font-weight: bold;
          }

          // Icon Styling
          i {
            font-size: 1.35rem;
            margin-right: 18px;
          }
        }
      }
    }
  }
}
