.header-component {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  padding: 5px 40px 5px 45px;
  width: 100%;
  height: 60px;
  background-color: var(--header-background);
  box-shadow: 0 0 0.5rem 0 rgb(0 0 0 / 60%);

  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    margin: 0;
    line-height: 1.05;
    font-size: 1.7rem;
    font-weight: 400;
  }

  .button-container {
    font-size: 1rem;

    .dropdown button {
      background: none;
      border: none;
      color: var(--text-brighter);
      transition: color 0.3s var(--default-transition);

      display: flex;
      align-items: center;

      &:hover {
        color: var(--text-muted);
      }
    }
  }
}
