.ticket-view {
  display: flex;
  flex-wrap: nowrap;

  .left-column {
    flex-grow: 1;
  }

  .right-column {
    width: 33.33333%;
  }

  // Edit button that fades in when we hover
  .edit-button {
    opacity: 0;
    display: none;
    transition: opacity 0.3s ease;
  }

  // Set a minimum height on cards with a header
  .card {
    min-height: 100px;

    // Fix collapsed margin
    margin-bottom: 20px;

    // Remove our top margin to prevent display issues in card
    .content h3 {
      margin-top: 0;
    }

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .header-buttons {
        display: flex;
      }
    }

    // Contains details under each sub-heading
    .details-container {
      margin-left: 10px;
      margin-right:10px;
      font-size: 0.875rem;
      min-height:50px;

      textarea{
        margin:0;
      }

      // Set the margin bottom of our details containers
      &:not(:last-child) {
        margin-bottom: 40px;
      }

      // Row under the 'details' heading
      .details-row {
        display: flex;
        flex-direction: row;
        margin: 20px 0;
        min-height: 25px;

        i {
          margin-left: 0;
          margin-right: 3px;
          font-size: 1.125rem;
        }

        // All divs within a row
        div {
          display: flex;
          align-items: center;

          // Styling for each type in our definition list
          dt,
          dd {
            display: inline-block;
          }

          dd {
            margin-left: 15px;
            font-weight: 500;
          }
        }

        // Selects the first div in each row (to evenly space them)
        div:first-child {
          width: 50%;
          margin-right: 20px;
        }
      }
    }
  }
}
