.kanban-card{
  background-color:var(--sub-card-background);
  padding:10px;
  box-shadow: 3px 3px 0.3rem 0 rgb(0 0 0 / 60%);
  overflow:visible !important;



  &:first-child{
    margin-top:10px;
  }

  &:last-child{
    margin-bottom:0;
  }

  cursor:grab;
  margin: 20px 0;
  border-radius:2px;

  .header{
    display:flex;
    justify-content: space-between;
    align-items:center;
    font-size: 0.75rem;

    .tag{
      background-color: #c7c3ec;
      color: #222;
      font-weight:bold;
    }

    h3{
        font-size:.8rem;

        a{
          transition:color var(--default-transition);

          .key {
            margin-right:0.5em;
            font-weight:500;
            color: var(--text-primary);
          }
        

          &:hover{
            color:var(--text-primary);
          }

        }
    }

    .kanban-card-corner{
      display:flex;
      align-items:center;

      // Override our dropdown button
      .dropdown-toggle {
        background:none;
        border:none;
        font-size:0.875rem;
        font-weight:500;
        color:var(--text-muted);
        padding:0 5px;
        &::after{
          display:none;
        }
      }
    }
  }
}