.app-view {
  // Contains sidebar + view
  .main-container {
    display: flex;
    margin-top: 60px;
    min-height: calc(100vh - 60px);

    // Contains each individual view
    .right-container {
      flex-grow: 1;
      overflow: auto;
      height: calc(100vh - 60px);

      // Scrollbar component
      .scrollbar-container {
        height: 100%;
        width: 100%;

        // Contains our .view and <footer>
        & > div:first-child {
          // 10px padding inside entire right-container
          // Cards + content must add the other 10px margin to fit correctly
          padding: 0 10px;
          display: flex;
          flex-direction: column;
        }

        .view {
          flex-grow: 1;
          padding-top: 10px;
        }
      }
    }
  }
}
