.document-model {
  // Remove background so that we can view our title
  .rst__rowContents,
  .rst__moveHandle {
    background-color: var(--card-background);
  }

  .rst__virtualScrollOverride {
    overflow: hidden !important;
  }

  // Styling for our tiny tree on the GDD page
  &.compact {
    margin-left: 10px;
    font-size: 0.875rem;

    // Hide expand/collapse button
    ul {
      padding-left: 15px;
      list-style: revert;

      a.active {
        color: white;
        font-weight: bold;
      }
    }
  }
}
