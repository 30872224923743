.footer{
  font-size:12px;
  display:flex;
  justify-content: space-between;
  align-items:center;
  padding:0 10px;

  .links{
    text-transform:uppercase;
    display:flex;

    li{
      margin-right:10px;
    }
  }

  // Set our hover color to blue
  a, .link-button{
    &:hover,
    &:focus{
      color:var(--text-primary);
    }
  }
}