.workspace-settings-view{
  max-width:1200px;

  .button-header{
    width:100%;
    display:flex;
    justify-content:space-between;
    align-items: center;
  }

  .card{
    margin-bottom:20px;
  }
}