* {
  box-sizing: border-box;
}

/* App Colors */
:root {
  /* Text colors */
  --text-normal: #cfd8e6;
  --text-brighter: #eee;

  --text-primary: #00a8ff;
  --text-primary-muted: #00a8ff66;

  --link-faded: #ffffffb3;

  --page-background: #0f1724;
  --header-background: #212c3d;
  --card-background: #1d2636;
  --card-list-highlight: #26334975;
  --card-header-highlight: #0f4877;
  --sub-card-background: #24354e;

  // Icon Colors
  --color-epic: #ff8c00;
  --color-feature: #9370db;
  --color-bug: #ef293d;
  --color-task: #32cd32;
  --color-spike: #ffffff;
  --color-story: #6495ed;

  --color-low: #00f2c3;
  --color-medium: #4ba6ff;
  --color-high: #ff8d72;
  --color-critical: #fd5d93;

  --default-transition: 0.3s ease;
}

body {
  margin: 0;

  // Google Web Font
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--page-background);
  color: var(--text-normal);

  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// Custom checkbox styling
input[type="checkbox"] {
  appearance: none;
  background-color: var(--text-normal);
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.75em;
  height: 0.75em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--card-header-highlight);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
