.gdd-page-view {
  .nav {
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 10px;
    font-size: 0.875rem;

    .nav-link {
      &::after {
        content: "/";
        margin-left: 0.5em;
        margin-right: 0.5em;
      }
    }
  }

  // Content within <card>
  .content {
    section > :first-child {
      margin-top: 0;
    }
  }

  .gdd-container {
    display: flex;

    .header-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      i {
        font-size: 1.2rem;
      }
    }

    .card {
      margin-bottom: 20px;
    }

    .left-column {
      max-width: 66.66666%;
      flex-grow: 1;
    }

    .right-column {
      width: 33.33333%;
    }
  }
}
