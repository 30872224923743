.markdown-editor {
  .preview-button {
    display: flex;
    justify-content: flex-end;
  }

  .editor {
    textarea {
      min-height: 200px;
      width: 100%;
      max-width: 100%;
    }
  }

  .preview {
    width: 100%;
    min-height: 156px;

    & > :first-child {
      margin-top: 0;
    }
  }
}
