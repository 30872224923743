.board-list-view{

  // Prevent full width to make easier to read
  max-width:1200px;

  // Header buttons
  .card-header{
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: flex-end;
    width:100%;

    p{
      margin:0;
    }
  }

  // Styling for when have no boards returned
  .no-boards{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    min-height:250px;

    i{
      font-size:2rem;
      color:white;
    }

    p{
      margin: 40px 0;

      // Create one link button
      .link-button{
        font-size:inherit;
        margin:0;
        padding:0;

        &:hover,
        &:focus{
          color:var(--text-primary);
        }
      }
    }
  }

  // Styling for our board list items
  .card-list{

    &.boards{

      li{
        padding:0;
        align-items:center;
        padding:0 10px;

        
        &:hover{
          > .dots{
            opacity: 1;
            visibility: visible;
          }
        }

        .dots{
          transition:opacity var(--default-transition), visibility var(--default-transition);
          opacity:0;
          visibility:hidden;
        }

        .board-link{
          flex-grow:1;
          display:flex;
          align-items: flex-end;
          font-size:0.875rem;
          position: relative;

          &.archived::after {
            content:'';
            border-bottom:1px solid white;
            position: absolute;
            top: 50%;
            z-index: 2;
    
            // Adjust for our 20px padding
            width: calc(100% - 5px);
          }

          .board-name{
            flex-grow:1;
          }

          .milestone{
            margin-right:20px;
          }
        }
      }
    }
  }

  // Container for pagination
  .pagination{
    display:flex;
    align-items: center;
    justify-content: center;
  }
}