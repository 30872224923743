.modal-card-header{
  width:100%;
  display:flex;
  align-items:center;
  justify-content:space-between;

  .close{
    height:24px;
    width:24px;
    padding:0;

    &:hover,
    &:focus{
      color:var(--text-primary);
    }

    i{
      font-size:24px;
      margin-left:0;
    }
  }



}