.ticket-list-view {
  max-width: 1200px;

  .card-header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .filter-container {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }

  // Styling for when have no tickets
  .no-tickets {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 250px;

    i {
      font-size: 2rem;
      color: white;
    }

    p {
      margin: 40px 0;

      // Create one link button
      .link-button {
        font-size: inherit;
        margin: 0;
        padding: 0;

        &:hover,
        &:focus {
          color: var(--text-primary);
        }
      }
    }
  }

  // Ticket link styling
  .tickets {
    li {
      position: relative;

      a {
        padding: 10px;
      }

      .strike-through {
        position: absolute;
        top: 32%;
        z-index: 2;

        // Adjust for our 20px padding
        left: 10px;
        width: calc(100% - 20px);
      }

      .ticket-link {
        flex-grow: 1;
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        flex-wrap: wrap;

        .type {
          margin: 0;
          font-size: 1.125rem;
        }

        .priority {
          margin-left: 10px;
          margin-right: 40px;
          font-size: 1.125rem;
        }

        .key {
          margin-right: 10px;
          color: var(--text-primary);
        }

        .summary {
          flex-grow: 1;
          margin-right: 10px;
        }
      }
    }
  }
}
