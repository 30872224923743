.fullscreen-modal{

  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;

  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100vh;
  background: #000000cc;
  backdrop-filter: blur(2px); // Doesn't work on firefox
  z-index:30;
  max-height: 100vh;
  overflow-y: auto;
 
  &.hidden{
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
  }

  // Contains the card within our modal
  .contents{

    width: 620px;
    margin: 0 auto;
    
    &.fullscreen-anim{
      animation-name: dropdown;
      animation-duration: 0.8s;
      animation-delay: 0.1s;
      animation-fill-mode: forwards;
    }

    &.fullscreen-anim-end{
        padding-top: 70px;
    }
  }
}

// Dropdown animation used by modal cards
@keyframes dropdown{
  from {transform:translateY(0)}
  to {transform:translateY(70px)}
}