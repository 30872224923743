.gdd-view{
  
  display:flex;
  flex-wrap:nowrap;


  // Card header container
  .header-container{
    display:flex;
    justify-content: space-between;
    align-items:center;
    flex-grow:1;
  }

  // Description cards
  .description{

    // Clear margins on children of content container in description class cards
    & > .content{
      :first-child{
        margin-top:0;
      }
    }

    & > .content{
      :last-child{
        margin-bottom:0;
      }
    }

  }

  .left-column{
    flex-grow:1;
    max-width:66.66666%;
  }

  .right-column{
    width:33.33333%;

    .card{
      margin-bottom:20px;
    }
  }

}