.lane{
  min-width:275px;
  max-width:275px;
  background-color:var(--card-background);
  margin:0 10px;
  padding:10px;
  border-radius: 4px;

  display:flex;
  flex-direction:column;
  // overflow-y:auto;

  &:first-of-type{
    margin-left:0;

  }

  &:last-of-type{
    margin-right:0;
  }


  // Lane heading
  h3{
    margin:0;
    font-size:1rem;
    font-weight:400;
  }

  .smooth-dnd-container{
    height:100%;
  }

}