.login-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;

  .card {
    width: 620px;
    margin: 0 auto;
    margin-top: 80px;
  }

  .remember-container {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 10px;
    margin-left: 10px;

    label {
      margin-bottom: 0;
    }
  }
}
