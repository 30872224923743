.App {
  // Contains Standardized Elements within our application theme

  // border/styling for tags
  .tag {
    display: inline-block;
    border-radius: 0.2rem;
    padding: 2px 10px;
    margin-left: 5px;
    margin-right: 5px;
    font-weight: 500;
    background-color: rgb(85, 85, 85);
    border: none;
    background-image: none;
  }

  // Buttons
  button {
    font-family: "Poppins", sans-serif;
  }

  // Form Elements

  // Styling for all icons contained within an <i> tag
  i {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5em;
  }

  // Helper class to flip an element 180 degrees
  .flipped {
    transform: rotate(180deg);
  }

  // Button stylized as a link
  .link-button,
  a {
    border: none;
    background: none;
    cursor: pointer;
    color: var(--link-faded);
    text-decoration: none;
    font-weight: 300;

    transition: color var(--default-transition);

    &:hover,
    &:focus,
    &:active {
      color: white;
    }
  }

  // ul + list styling for board list and task list
  .card-list {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      border-top: 0.0625rem solid hsla(0, 0%, 100%, 0.10196078431372549);
      min-height: 50px;
      transition: background-color var(--default-transition);
      align-items: center;
      padding: 0 10px;

      &:first-child {
        border: none;
      }

      &:hover {
        background-color: var(--card-list-highlight);
      }
    }
  }

  // Headings on each page
  h2 {
    font-weight: 200;
    color: white;
    font-size: 1.5rem;
    margin: 10px;
  }

  h3 {
    font-weight: 400;
    font-size: 1.4375rem;
    margin: 10px 0;
  }

  // Reset padding/margins on UL
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

// Input
// input {
//   height: 35px;
//   border-radius: 2px;
//   border: 1px solid rgba(0, 0, 0, 0);
//   background: rgba(97, 119, 145, 0.25);
//   transition: border-color var(--default-transition),
//     box-shadow var(--default-transition);
//   color: white;
//   font-size: 1rem;
//   padding: 5px;

//   &:focus {
//     outline: none;
//     border-color: rgb(0, 150, 250);
//     box-shadow: 0 0 3px 1px rgb(74, 173, 240, 0.7);
//   }

//   &:hover {
//     border-color: rgb(0, 150, 250);
//   }
// }

// // TextAra
// textarea {
//   min-height: 100px;
//   border-radius: 2px;
//   border: 1px solid rgba(0, 0, 0, 0);
//   background: rgba(97, 119, 145, 0.25);
//   transition: border-color var(--default-transition),
//     box-shadow var(--default-transition);
//   color: white;
//   font-size: 1rem;
//   padding: 5px;

//   &:focus {
//     outline: none;
//     border-color: rgb(0, 150, 250);
//     box-shadow: 0 0 3px 1px rgb(74, 173, 240, 0.7);
//   }

//   &:hover {
//     border-color: rgb(0, 150, 250);
//   }
// }

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea {
  border-radius: 0.4285rem;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  display: block;
  height: calc(2.25rem + 2px);
  line-height: 1.42857;
  color: #ffffffcc;
  background-color: initial;
  background-clip: padding-box;
  border: 1px solid #2b3553;
  box-shadow: none;
  overflow: visible;
  padding: 10px 18px;
  outline: none;

  &:focus {
    border-color: var(--text-primary);
    outline: none;
  }
}

textarea {
  min-height: 100px;
}

form {
  display: flex;
  flex-direction: column;

  // Inputs within a form
  input,
  textarea {
    margin-bottom: 20px;
    margin-left: 10px;
    width: calc(100% - 10px);
  }

  label {
    font-size: 0.75rem;
    margin-bottom: 10px;
  }

  button {
    align-self: flex-end;
  }
}

// Stick button to right side of form
button.primary {
  max-width: 150px;
  padding: 5px 10px;
  border: none;
  font-size: 0.875rem;
  border-radius: 2px;
  color: white;
  background: var(--sub-card-background);
  transition: background var(--default-transition);
  cursor: pointer;

  &:hover {
    background: rgba(27, 99, 172, 0.8);
  }
}
