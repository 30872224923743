.board-view{
  height:100%; 

  .heading{
    display:flex;
    justify-content: space-between;
    align-items: flex-end;
  

    h2{
      margin-bottom:0;
    }
  }

  .board{
    // Height of footer
    min-height: calc(100% - 60px);
    padding:20px 0;
    margin:0 10px;
    display:flex;
    align-items:flex-start;
    overflow-y:auto;
  }

  .dropdown{
    display:flex;
    flex-direction:column;
  }
  
}